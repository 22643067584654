.bg_container {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(90.07deg, #FFFDFD 0.06%, #F7D8D8 99.94%);

}

.hapinoy_logo_container {
    position: absolute;
    top: 53px;
    left: 53px;
    width: 435.2px;
    height: 157.8px;
    z-index: 2;
}

.hapinoy_logo {
    position: relative;
    width: 100%;
    height: 100%;
}

.hapinoy_login {}

.container_login_form {
    position: absolute;
    width: 60vh;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 17vh;
    border-radius: 5px;
    padding-top: 6vh;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 8vh;
    z-index: 2;
}

.form_header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 8vh;
}

.text_login {

    top: 0;
    font-weight: 800;
    font-size: 40px;
    line-height: 30px;
    color: #A9489F;
}

.line {
    position: relative;
    box-sizing: border-box;
    width: 35px;
    height: 8px;

    background: linear-gradient(90deg, #5C3190 7.1%, #A9489F 93.72%);
    border-radius: 5px;
    transform: matrix(0, 1, 1, 0, 0, 0);
}

.text_description {
    width: 50%;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    color: #5C3190;
}

.text_email {
    font-size: 15px;
    line-height: 15px;

    color: #5C3190;
}

.text_password {
    font-size: 15px;
    line-height: 15px;

    color: #5C3190;
}

.error {
    position: absolute;
    top: 10px;
}

.success {
    position: absolute;
}

.form_login {
    text-align: left;
}

.login_button {
    background: linear-gradient(90deg, #B840AB 7.1%, #E98591 102.16%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}



.login_input {
    border: 0;
    outline: none;
    background: rgb(255, 255, 255, 0);
    border-bottom: 1px solid #134FA0;
    width: 100%;
}

.background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {

    .bg_container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hapinoy_logo_container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hapinoy_logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .container_login_form {
        position: absolute;
        width: 100%;
        margin: auto;
        left: 0;
        right: 0;
        margin-top: 17vh;
        border-radius: 5px;
        padding-top: 6vh;
        padding-left: 5vh;
        padding-right: 5vh;
        padding-bottom: 8vh;
        z-index: 2;
    }


}


.hapinoy_front_page_image {
    width: 10%;
    height: 170px;
}
